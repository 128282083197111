<template>
  <div class="secure-report container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.tcIocCampVisits }}</h1>
    </header>
    <section class="container bg-block">
      <h3>{{ reportName }}</h3>
      <b-form-group class="form-element">
        <div class="form_label bold"><strong>{{ translations.tcRegion }}</strong></div>
        <b-form-select class="form-control g-select w-full" :options="regions" v-model="region.org_key" :plain="true"
          @change="regionChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label bold"><strong>{{ translations.tcCountry }}</strong></div>
        <b-form-select class="form-control g-select w-full" :options="countries" v-model="country.org_key" :plain="true"
          @change="countryChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label bold"><strong>{{ translations.tcCamp }}</strong></div>
        <b-form-select class="form-control g-select w-full" :options="camps" v-model="camp.org_key" :plain="true"
          @change="campChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(ReportDetail, index) in this.reportUrls" :key="`g${index}`">
          <b-link target="_blank" :href="ReportDetail.value"><strong>{{ ReportDetail.text }}</strong></b-link>
        </div>
      </b-form-group>
      <b-form-group>
        <b-button class="btn btn-tertiary btn-w-med ml-4" size="sm" id="btnCancelForm" @click="handleCancelClick">{{
            translations.tcCancel
        }}</b-button>
      </b-form-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'ioc-camp-visits',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      title: 'ICC Camp Visits',
      values: {},
      reportName: '',
      reportParamParentKey: '',
      regions: [
        {
          text: 'Select a Region',
          value: constantData.empty_guid,
        },
      ],
      countries: [
        {
          text: 'Select a Country',
          value: constantData.empty_guid,
        },
      ],
      camps: [
        {
          text: 'Select a Camp',
          value: constantData.empty_guid,
        },
      ],
      reportUrls: null,
      region: {
        org_key: null,
        org_name: null,
      },
      country: {
        org_key: null,
        org_name: null,
      },
      camp: {
        org_key: null,
        org_name: null,
      },
      reportUrls: null,
      reportCountryData: {
        ind_key: null,
        org_key: null,
      },
      reportCampData: {
        ind_key: null,
        org_key: null,
      },
      reportUrlsData: {
        ind_key: null,
        org_key: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getRegions: 'secureReport/getRegions',
      getCountries: 'secureReport/getCountries',
      getCamps: 'secureReport/getCamps',
      getCampUrls: 'secureReport/getCampUrls',
      getRegionUrls: 'secureReport/getRegionUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      await this.getRegions()
      this.regions = this.regionDetails.map((item) => {
        return { text: item.org_name, value: item.org_key }
      })
      this.regions.unshift({ text: this.translations['tcSelectARegion'], value: constantData.empty_guid })
      this.region.org_key = constantData.empty_guid
      this.country.org_key = constantData.empty_guid
      this.camp.org_key = constantData.empty_guid
      this.reportUrls = null
      this.setLoadingStatus(false)
    },
    async regionChange(evt) {
      this.setLoadingStatus(true)
      if (!!this.region.org_key) {
        this.reportCountryData.ind_key = this.userId
        this.reportCountryData.org_key = this.region.org_key
        await this.getCountries(this.reportCountryData)
        this.countries = this.countryDetails.map((item) => {
          return { text: item.t_org_name, value: item.t_org_key }
        })
        this.countries.unshift({ text: this.translations['tcSelectACountry'], value: constantData.empty_guid })
        this.country.org_key = constantData.empty_guid
        this.reportUrls = null
      }
      this.setLoadingStatus(false)
    },
    async countryChange(evt) {
      this.setLoadingStatus(true)
      this.reportCampData.ind_key = this.userId
      this.reportCampData.org_key = this.country.org_key
      await this.getCamps(this.reportCampData)
      this.camps = this.campDetails.map((item) => {
        return { text: item.t_org_name, value: item.t_org_key }
      })
      this.camps.unshift({ text: this.translations['tcSelectACamp'], value: constantData.empty_guid })
      this.camp.org_key = constantData.empty_guid
      this.reportUrls = null
      this.setLoadingStatus(false)
    },
    async campChange(evt) {
      this.setLoadingStatus(true)
      this.reportUrlsData.ind_key = this.userId
      this.reportUrlsData.org_key = this.camp.org_key
      await this.getCampUrls(this.reportUrlsData)
      this.reportUrls = this.campUrlDetails.map((item) => {
        return { text: item.Name, value: item.URL }
      })
      this.setLoadingStatus(false)
    },
    handleCancelClick() {
      this.$router.push({ path: `/reports/report-center` })
    },
  },
  async created() {
    await Promise.all([
      this.page_load(),
      this.getViewTranslations()
    ])
      .then(() => {
        this.title = this.translations['tcTitle']
        this.regions[0].text = this.translations['tcSelectARegion']
        this.countries[0].text = this.translations['tcSelectACountry']
        this.camps[0].text = this.translations['tcSelectACamp']
      })
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      regionDetails: 'secureReport/regions',
      countryDetails: 'secureReport/countries',
      campDetails: 'secureReport/camps',
      campUrlDetails: 'secureReport/campUrls',
      regionYearDetails: 'secureReport/regionReportYears',
      reportParentKey: 'reportParameter/reportParentKey',
      selectedReportKey: 'reportParameter/selectedReportKey',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
    }),
  },
  components: {},
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 300px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox+div {
  display: inline-block;
}

.rpt-tab {
  padding-left: 4em;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}
</style>
